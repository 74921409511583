<template>
  <div class="todo-app-list">
    <ekle-headers
      :title="'Dil Seçenekleri'"
      :baslik="form.urun_adi"
      @mqShallShowLeftSidebar="$emit('mqShallShowLeftSidebar', $event)"
      @removeProduct="$emit('removeProduct', $event)"
    />
    <vue-perfect-scrollbar :settings="scrollbarSettings" class="todo-task-list-wrapper scroll-area p-2">
      <b-card-normal :title="'Diğer bilgileri girin.'">
        <template v-slot:body>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Asgari yaş sınırı" description="Örnek olarak 18 veya 18+ gibi girebilirsiniz.">
                    <b-form-input class="rounded-0" v-model="form.asgari_yas" aria-describedby="input-1-live-feedback" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Maksimum Kapasite" description="Örnek olarak 50 girebilirsiniz.">
                    <b-form-input class="rounded-0" v-model="form.maksimum_kisi" aria-describedby="input-1-live-feedback" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Maksimum Yaş" description="Örnek olarak 50 girebilirsiniz.">
                    <b-form-input class="rounded-0" v-model="form.maksimum_yas" aria-describedby="input-1-live-feedback" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Toplam Süre" description="Örnek olarak ( 9 Saat, 1 Gün, 2 Hafta ) vb.  girebilirsiniz.">
                    <b-form-input class="rounded-0" v-model="form.toplam_sure" aria-describedby="input-1-live-feedback" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Toplam Süre Türü" description="Örnek olarak ( Saat, Gün, Hafta ) vb.  girebilirsiniz.">
                    <v-select
                      v-model="form.toplam_sure_turu"
                      :options="toplamSureTuru"
                      :reduce="(tur) => tur.id"
                      label="title"
                      :clearable="false"
                      class="invoice-filter-select d-block"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Rezervasyon Kapatma zamanı"
                    description="Örnek olarak ( 1 ) olarak yazabilirsiniz saat cinsinden hesaplanacaktır."
                  >
                    <b-form-input
                      class="rounded-0"
                      type="number"
                      v-model="form.rezervasyon_kapama"
                      aria-describedby="input-1-live-feedback"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group label="Ürün Yayınlama" description="Ürün yayın durumunu belirleyiniz.">
                    <v-select
                      v-model="form.statu"
                      :options="statuler"
                      :reduce="(statu) => statu.id"
                      label="title"
                      :clearable="false"
                      class="invoice-filter-select d-block"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <div class="d-flex justify-content-between align-items-center py-1">
                    <label for="statu" class="font-weight-bold h6">Seo Optimize</label>
                    <b-form-checkbox :checked="form.seo" v-model="form.seo" switch />
                  </div>
                </b-col>
              </b-row>
              <b-row class="pt-1">
                <b-col cols="12" md="6" lg="6">
                  <b-button squared block type="submit" variant="primary">
                    <feather-icon icon="CheckSquareIcon" class="mr-50" />
                    <span class="align-middle">{{ form._id == null ? 'Kaydet' : 'Güncelle' }}</span>
                  </b-button>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <b-button squared block>
                    <feather-icon icon="XSquareIcon" class="mr-50" />
                    <span class="align-middle">İptal</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </template>
      </b-card-normal>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import EkleHeaders from '../component/ekleHeader.vue';
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import vSelect from 'vue-select';
export default {
  components: {
    draggable,
    VuePerfectScrollbar,
    EkleHeaders,
    BCardNormal,
    vSelect,
  },
  props: {
    form: {
      type: Object,
      required: true,
      default: {},
    },
    scrollbarSettings: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    show: false,
    toplamSureTuru: [
      {
        id: 'saat',
        title: 'Saat',
      },
      {
        id: 'gun',
        title: 'Gün',
      },
      {
        id: 'hafta',
        title: 'Hafta',
      },
      {
        id: 'ay',
        title: 'Ay',
      },
    ],
    statuler: [
      {
        id: 1,
        title: 'Aktif',
      },
      {
        id: 2,
        title: 'Pasif',
      },
      {
        id: 3,
        title: 'Taslak',
      },
    ],
  }),

  methods: {
    onSubmit() {
      this.$emit('onSubmit');
    },
  },
};
</script>
<style lang="scss"></style>
